/*
Bridge Css Code
*/

/*----- HTML Code Start ----- */

html {
  scroll-behavior: smooth;
}

/*----- HTML Code End ----- */

/*----- Body Code Start ----- */

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  background-color: #f8f9ff;
}

/*----- Body Code End ----- */

/*----- Web Layout Color Start ----- */

.webRedColor {
  background-color: #f00001 !important;
  background: #f00001 !important;
}

.webDarkBlue {
  background-color: #172755 !important;
}

/*----- Web Layout Color End ----- */

/*----- Web Layout Text Color Start ----- */

.colorDarkBlue {
  color: #172755 !important;
}

.colorLigtGrey {
  color: #757575 !important;
}

/*----- Web Layout Text Color End ----- */

/*----- Heading Css Style Start ----- */

h1 {
  font-size: 47px;
  color: white;
  font-weight: 500;
  text-align: left;
  line-height: 65.63px;
  padding: 60px 0 0 0;
}

h2 {
  font-size: 40px;
  color: #172755;
  font-weight: 700;
  text-align: center;
  line-height: 46.88px;
}

h3 {
  font-size: 24px;
  color: #172755;
  font-weight: 700;
  text-align: center;
  line-height: 28.13px;
  padding: 25px 0 0 0;
  height: 81.25px;
}

h4 {
  font-size: 22px;
  color: #172755;
  font-weight: 600;
  text-align: right;
  line-height: 28.13px;
  padding: 25px 0 0 0;
  height: 81.25px;
}

p {
  font-size: 17px;
  color: white;
  font-weight: 300;
  text-align: left;
  line-height: 27px;
  padding: 15px 0 0 0;
  opacity: 0.8;
}

/*----- Heading Css Style End ----- */

/*----- Home Page Css Style Start ----- */

/*----- Navbar Section Start ----- */
.navbar {
  width: 100%;
}

.navbar-inner {
  width: 100%;
  height: 120px;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 16px !important;
  color: white !important;
  opacity: 0.8;
  font-weight: 300 !important;
  padding: 0 20px !important;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #f00001 !important;
  font-weight: 500 !important;
  opacity: 1;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: white;
  opacity: 1;
}

.navSignUp {
  width: 141px;
  height: 40px;
  border: 1px solid #465b95 !important;
  border-radius: 50px !important;
  font-size: 15px !important;
  color: white !important;
  opacity: 0.8;
  font-weight: 300 !important;
  margin-left: 20px;
  padding-top: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.navSignUp:hover {
  border: 1px solid #465b95 !important;
  background-color: #465b95 !important;
  color: white !important;
  opacity: 1;
}

/*----- Navbar Section End ----- */

/*----- Banner Section Start ----- */
#homeBanner {
  width: 100%;
  height: 920px;
  background-image: url("../assets/images/home/banner-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 18px 0;
}

.tryFreeButton {
  width: 238px;
  height: 50px;
  padding: 10px, 80px, 10px, 80px !important;
  border-radius: 68px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: white !important;
  text-align: center !important;
  margin: 30px 0 0 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.submitButton {
  height: 40px;
  padding: 10px, 80px, 10px, 80px !important;
  border-radius: 0px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: white !important;
  text-align: center !important;
  margin: 30px 0 0 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.tryFreeButton:hover {
  background-color: white !important;
  background: white !important;
  color: #f00001 !important;
}
.submitButton:hover {
  background-color: white !important;
  background: white !important;
  color: #f00001 !important;
}
.bannerColumn {
  padding-left: 100px !important;
}

.bannerColumn h1 {
  font-size: 40px !important;
  color: white;
  font-weight: 500 !important;
  text-align: left;
  line-height: 65.63px !important;
  padding: 60px 0 0 0;
}

.bannerColumn p {
  font-family: "Roboto", sans-serif;
}

.bannerImage {
  width: 100%;
  margin: 100px 0 0 0;
}

/*----- Banner Section End ----- */

/*----- About Health Section Start ----- */
#aboutHealth {
  padding: 70px 0 0 0;
}

.aboutHealthParagrapg p {
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: #757575;
  font-weight: 400;
}

.health {
  margin: 30px 0 0 0;
}

form.health input[type="text"] {
  padding: 20px 30px;
  font-size: 16px;
  border: 1px solid #cccccc;
  float: left;
  width: 90%;
  background: white;
  color: #757575;
  border-radius: 50px;
  height: 60px;
  position: absolute;
}

form.health button {
  float: right;
  width: 17.6%;
  padding: 10px;
  background: #f00001;
  color: white;
  font-size: 16px;
  border: 1px solid #f00001;
  border-left: none;
  cursor: pointer;
  height: 60px;
  border-radius: 50px;
  position: absolute;
  right: 0;
  font-weight: 400;
}

form.health button:hover {
  background: #f00001;
}

form.health::after {
  content: "";
  clear: both;
  display: table;
}

/*----- About Health Section End ----- */

/*----- Explore Bridgeocean Section Start ----- */
#bridgeocean {
  padding: 160px 0 0 0;
}

.bridgeoceanBox {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 35px 15px;
  box-shadow: 0px 13px 15px #dfe4f1;
  margin: 30px 0 0 0;
  height: 490px;
  text-align: center;
}

.exploreParagrapg p {
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: #757575;
  font-weight: 400;
  padding: 0;
  height: 216px;
}

.educateButton {
  width: 100%;
  height: 50px;
  font-size: 16px !important;
  text-align: center !important;
  font-weight: 400 !important;
  color: white !important;
  border-radius: 68px !important;
  margin: 26px 0 0 0;
}

.educateButton:hover {
  color: white !important;
}

.exploreColumn {
  padding-left: 6px;
  padding-right: 6px;
}

.graphData {
  padding: 100px 0 0 0;
}

.graphData1 {
  padding: 40px 0 0 0;
}

.alignLeft {
  text-align: left;
}

.w60 {
  width: 90%;
  padding: 80px 0 0 0;
}

.graphParapraph p {
  font-size: 18px;
  text-align: left;
  color: #757575;
  line-height: 27px;
  width: 82%;
  font-weight: 400;
  padding: 20px 0 0 0;
}

.w100 {
  width: 100% !important;
}

.graphDataSet {
  padding-left: 109px;
  padding-right: 0;
}

.text-center {
  text-align: center;
}

.healthImage {
  width: 40%;
  margin: 40px 0 0 0;
}

.countryBox {
  width: 200px;
  height: 104px;
  background-color: white;
  border-radius: 16px;
  padding: 12px;
  text-align: center;
  box-shadow: 0px 8px 23px #dfe4f1;
  margin: 12px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watchFlex {
  display: grid;
  justify-content: flex-end;
}

.video {
  margin: 12px 0 0 0;
  float: right;
}

/*----- Explore Bridgeocean Section End ----- */

/*----- Footer Section Start ----- */
footer {
  width: 100%;
  height: 253px;
  background-image: url("../assets/images/footer/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 190px 0 0 0;
  background-color: white !important;
}

.footerTop {
  width: 100%;
  height: 1px;
  background-color: #2a407c;
}

.footerFlex {
  display: flex;
  justify-content: space-between;
}

.footerTitle p {
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  color: white;
  opacity: 0.8;
  padding: 19px 0 0 0;
  margin: 0;
}

.footerTerm {
  font-size: 14px;
  font-weight: 300;
  color: white;
  opacity: 0.8;
  margin: 0 20px 0 0;
}

.footerTermMargin {
  margin: 19px 0 0 0;
}

/*----- Footer Section End ----- */

/*----- Home Page Css Style End ----- */

/*----- Contact Us Page Css Style Start ----- */
#contact {
  padding: 80px 0;
}

.conatctTitle p {
  font-size: 16px;
  color: #8794ba;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
  padding: 8px 0;
}

.contactMainBox {
  width: 100%;
  background-color: white;
  box-shadow: 0px 13px 15px #dfe4f1;
  height: 650px;
  border-radius: 10px;
  margin: 35px 0 0 0;
  padding: 15px;
}

.contactInnerBox {
  width: 100%;
  background-color: #172755;
  height: 600px;
  border-radius: 10px;
  padding: 35px 35px;
}

.contactInnerBox::before {
  content: "";
  width: 138px;
  height: 138px;
  background-image: url("../assets/contact/Ellipse-794.png");
  background-repeat: no-repeat;
  position: absolute;
  right: 90px;
  bottom: 50px;
  z-index: 1;
}

.contactInnerBox::after {
  content: "";
  width: 191px;
  height: 156px;
  background-image: url("../assets/contact/Ellipse-793.png");
  background-repeat: no-repeat;
  position: absolute;
  right: 15px;
  margin-top: -125px;
  border-bottom-right-radius: 10px;
}

.preview-suggestions-box {
  position: absolute;
  left: 20px;
  top: 95px;
  padding: 1rem;
  grid-template-columns: 1fr;
  z-index: 1000;
  width: 96%;
  border-radius: 1rem;
  background: #f5f1f1;
  -webkit-backdrop-filter: blur(5px);
}

.preview-suggestions-box h5 {
  color: #000000;
  font-size: 1.65rem;
  cursor: pointer;
  font-weight: 500;
  margin: 0;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  text-align: left;
}

.preview-suggestions-box h5:hover {
  color: #5d0076;
  background-color: rgba(92, 0, 118, 0.15);
}

.conatctTitle h3 {
  font-size: 24px;
  color: white;
  font-weight: 600;
  line-height: 28.13px;
  text-align: left;
  margin: 0;
  padding: 0;
  height: auto;
}

.textLeft {
  text-align: left !important;
  padding: 15px 0 0 0 !important;
}

.contactPhone {
  font-size: 16px;
  color: white;
  font-weight: 300;
  line-height: 18.75px;
  padding-left: 15px;
  padding-top: 5px;
}

.contactMT {
  margin-top: 110px;
}

.contactFlex {
  display: flex;
}

.socialMT {
  margin-top: 142px !important;
}

#contactWrp {
  padding: 133px 0;
  padding-right: 15px;
}

label {
  font-size: 14px !important;
  color: #172755 !important;
  text-align: left;
  font-weight: 400 !important;
  margin: 0;
}

.contactInput {
  border-radius: 0;
  border-bottom: 1px solid #cccccc !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  color: #757575;
  font-weight: 500;
  padding-left: 0;
  height: 50px;
  font-size: 16px;
}

.contactInput::placeholder {
  color: #757575;
  font-weight: 500;
}

.form-control:focus {
  border-color: unset;
  box-shadow: unset;
}

.contactTextarea {
  border-radius: 0;
  border-bottom: 1px solid #cccccc !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  color: #757575;
  font-weight: 500;
  padding-left: 0;
  height: 44px !important;
  font-size: 16px;
  resize: unset;
}

.contactBTNFlex {
  display: flex;
  justify-content: flex-end;
}

.sendMessageButton {
  width: 169px;
  height: 48px;
  text-align: center !important;
  font-size: 16px !important;
  color: white !important;
  font-weight: 400 !important;
  line-height: 18.75px !important;
  border-radius: 50px !important;
  margin: 25px 0 0 0 !important;
  box-shadow: 0px 6px 15px #dfe4f1 !important;
}

/*----- Contact Us Page Css Style End ----- */

/*----------   Terms and Conditions  -----------*/

.terms p {
  text-align: left;
  font-size: 13px;
  color: #7f7a7a;
  padding: 0px !important;
}

.terms h2 {
  padding-bottom: 5px !important;
  font-size: 18px !important;
  text-align: left !important;
}

.title {
  text-align: left;
  font-size: 13px;
  font-weight: 600 !important;
  color: #172755 !important;
  padding: 0px 0px 5px 13px !important;
}

.page-title {
  font-size: 20px !important;
  text-align: center;
}

.page-title h1 {
  color: #172755;
  text-align: center;
  padding: 0px 0px 50px 0px;
  font-size: 25px !important;
  font-weight: 600;
}

@media only screen and (min-width: 1600px) {
  .bannerColumn {
    padding-left: 12%;
  }

  .bannerImage {
    margin: 40px 0 0 0;
  }
}

@media screen and (max-width: 750px) {
  .bannerColumn {
    padding-left: unset !important;
    padding-inline: 15px !important;
    text-align: center;
  }

  .tryFreeButton {
    height: 40px;
  }

  #homeBanner {
    height: 740px;
  }

  .bannerImage {
    display: none !important;
  }

  form.health input[type="text"] {
    font-size: 11px;
    width: 85%;
    height: 50px;
  }

  form.health button {
    width: 27.6%;
    font-size: 12px;
    height: 50px;
  }

  .educateButton {
    height: 35px;
    margin: 46px 0 0 0;
  }

  .graphData {
    width: 100% !important;
    padding: 20px 15px 0 15px;
  }

  .graphData1 {
    width: 100% !important;
    padding: 20px 15px 0 15px;
  }

  .graphData2 {
    width: 100% !important;
    padding: 20px 15px 0 15px;
  }

  .healthImage {
    width: 60%;
    margin: 20px 0 0 0;
  }

  .countryBox {
    width: 100% !important;
  }

  .watchFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .alignLeft {
    text-align: center !important;
  }

  .graphParapraph p {
    text-align: center !important;
    width: 100%;
  }

  .navSignUp {
    height: 30px;
    width: unset !important;
  }

  .navbar-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-nav {
    flex-direction: row !important;
  }

  .navbar-light .navbar-nav .nav-link {
    padding: 0px 10px !important;
    font-size: 13px !important;
  }

  .navSignUp {
    padding-top: unset !important;
  }

  .bannerColumn h1 {
    font-size: 20px !important;
    line-height: 35.63px !important;
    padding: 0px 0 0 0;
  }

  footer {
    height: unset !important;
    padding: 90px 0 0 0;
  }

  .contactInnerBox::after {
    display: none !important;
  }

  .contactInnerBox::before {
    display: none;
  }

  #contact {
    height: 100% !important;
    background-color: white !important;
    padding: 30px 0px 0px 0px;
  }

  #contactWrp {
    padding: 60px 0px 0px 0px !important;
    padding-right: unset !important;
  }

  .contactMainBox {
    height: unset !important;
  }

  .footerTerm {
    margin: 0 0px 0 0;
  }

  .footerTermMargin {
    display: flex;
    justify-content: space-between;
  }

  .contactInnerBox {
    padding: 45px 15px;
    height: unset !important;
  }

  .contactMT {
    margin-top: 50px;
  }

  .socialMT {
    margin-top: 50px !important;
  }

  .conatctTitle {
    padding: 0 !important;
  }
}