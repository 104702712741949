@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  outline: 0;
}

:root {
  font-size: 10px;
}

body,
html {
  width: 100%;
  max-width: 100%;
  background: white !important;
  /* background: linear-gradient(45deg, #5d0076, #000000, #330040);  */
}

.box {
  width: 100%;
  max-width: 1350px;
  padding: 0 5rem;
  margin: 0 auto;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.bridgeocean {
  width: 100%;
  min-height: 100vh;
}

.logo-box {
  width: 100%;
  padding: 3.5rem 0;
}

.logo-box img {
  height: 6.5rem;
  object-fit: contain;
}

h1 {
  font-size: 5rem;
  font-weight: 700;
  color: #fff;
}

h2 {
  font-size: 4rem;
  font-weight: 600;
  color: #fff;
}

h3 {
  font-size: 2.5rem;
  font-weight: 500;
  color: #fff;
}

h4 {
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
}

p {
  font-size: 1.65rem;
  font-weight: 400;
  color: #fff;
  opacity: 0.85;
}

.glass-box p {
  color: #172755 !important;
}

.ppms-details-box p {
  color: #757575 !important;
}

.ppms-details-box th {
  color: #172755 !important;
  border: 1px solid #c9c2c2;
}

.ppms-details-box td {
  color: #172755 !important;
  border: 1px solid #c9c2c2;

}

/* .recharts-layer path {
  fill :  unset !important
} */
.glass-box {
  width: 100%;
  padding: 3.5rem;
  border-radius: 1.5rem;
  background: rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  overflow: hidden;
}

.glass-box-locked {
  pointer-events: none;
  filter: blur(30px);
  overflow: hidden;
}

.glass-box>*:not(.glass-box-locked-box) {
  position: relative;
  z-index: 2;
}

.glass-box-locked-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.glass-box-locked-box p {
  font-size: 2.5rem;
  font-weight: 700;
}

.glass-box-locked-box button {
  width: max-content;
  margin: 1.5rem 0 0 0;
  padding: 1.5rem 2.5rem;
}

.precede-chart-box {
  width: 100%;
  height: 35rem;
}

.recharts-legend-item {
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
}

.recharts-legend-item .recharts-surface {
  height: 15px;
  width: 15px;
  margin: -0.5rem 0 0 0;
}

text,
tspan,
.recharts-legend-item-text {
  font-size: 1.25rem;
}

.recharts-default-tooltip {
  font-size: 1.5rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border: 1px solid rgba(0, 0, 0, 1) !important;
  fill: #000000 !important;
}

.ppmsdb1 {
  padding: 5rem 0 0 0;
}

.ppmsdb2 {
  padding: 7.5rem 0;
}

.ppms-details-box {
  width: 100%;
}

.ppms-details-box p {
  margin: 0.5rem 0 0 0;
}

.ppms-details-box h4 {
  margin: 2.5rem 0;
}

.ppms-details-box h4 span {
  text-transform: uppercase;
}

table {
  width: 100%;
  /* background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);*/
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-collapse: collapse;
  border-radius: 1rem;
  overflow: hidden;
}

th {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1.25rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.15);
}

td {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1.25rem;
  font-size: 1.65rem;
  color: #fff;
  text-align: left;
  text-transform: capitalize;
}

tbody tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

.gb2 {
  margin: 5rem 0;
}

.page1-container footer {
  margin: 10rem 0 0 0;
}

.sentiment-chart-box {
  width: 100%;
  height: 25rem;
}

.sl-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: 5rem;
  margin: 2.5rem 0 5rem 0;
  /* border-radius: 1.5rem;
  padding: 3.5rem;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3); */
}

.sentiment-level-value-box {
  width: 100%;
  padding: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.sentiment-level-value-box h1 {
  text-transform: uppercase;
  font-size: 5rem;
  line-height: 4rem;
  margin: 1rem 0 0 0;
}

.recharts-default-legend {
  display: flex;
  justify-content: flex-start;
}

.precede-chart-box .recharts-default-legend {
  justify-content: center;
}

.sd-grid {
  width: 100%;
  margin: 1.5rem 0 5rem 0;
  padding: 3.5rem;
  /* border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3); */
}

.sentiment-timeline-chart-box {
  width: 100%;
  height: 40rem;
  margin: 5rem 0 0 0;
  /* border-radius: 1.5rem;
  padding: 3.5rem;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3); */
}

.sentiment-timeline-chart-box .recharts-responsive-container {
  width: calc(100% + 20px) !important;
  margin: 0 0 0 -20px !important;
}

.clinical-domain-analysis-box .recharts-responsive-container {
  width: calc(100% + 20px) !important;
  margin: 0 0 0 -20px !important;
}

.clinical-domain-analysis-box {
  width: 100%;
  height: 40rem;
  margin: 5rem 0 0 0;
}

text {
  fill: #172755 !important;
}

.sentiment-distribution-chart-box {
  width: 100%;
  height: 30rem;
}

.recharts-tooltip-cursor {
  fill: rgba(255, 255, 255, 0.075);
}

.clinical-domain-analysis-box .recharts-legend-wrapper {
  bottom: -5px !important;
}

/* Page 2 - Index */

.page2-hero-grid {
  width: 100%;
  padding: 7.5rem 0;
  display: grid;
  grid-template-columns: 1fr 575px;
  grid-template-rows: auto;
  grid-gap: 5rem;
}

.page3-hero-grid {
  width: 100%;
  padding: 7.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page3-hero-grid h1 {
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;
}

.page3-hero-grid>p {
  max-width: 750px;
  text-align: center;
  margin: 5rem auto 10rem auto;
  font-size: 2rem;
}

.page2-container h1 span {
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}

.page2-container h1 i {
  font-style: normal;
  /* color: #df0000; */
}

.page4-form {
  width: 100%;
  max-width: 650px;
  margin: 5rem auto 7.5rem auto;
  padding: 3.5rem;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.5rem;
}

.page4-form h3 {
  display: flex;
  align-items: center;
}

.subscribe-link {
  background: linear-gradient(45deg, #5d0076, #000000);
  padding: 15px 10px;
  font-weight: 800;
  font-size: 10px;
}

.page4-form h3 span {
  height: max-content;
  display: flex;
  margin: 0 0 0 1.5rem;
  cursor: pointer;
}

.page4-form input,
.page4-form select {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.65rem;
  color: black;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgb(199 191 191 / 30%);
  transition: 0.35s;
}

.page4-form select option {
  color: #000;
}

.page4-form input::placeholder,
.page4-form select::placeholder {
  color: #fff;
  opacity: 0.65;
}

.page4-form input:focus,
.page4-form select:focus,
.page4-form select:hover,
.page4-form input:hover {
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.page4-form button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.page4-form button .multi-ripple div,
.page4-form button .multi-ripple {
  width: 2.75rem !important;
  height: 2.75rem !important;
  /* display: none; */
}

button {
  width: 100%;
  background: linear-gradient(45deg, #5d0076, #000000);
  padding: 1.5rem;
  font-size: 1.65rem;
  color: #fff;
  border: 0;
  outline: 0;
  border-radius: 1rem;
  cursor: pointer;
  transition: 0.35s;
}

button:disabled {
  background: linear-gradient(45deg, #000, #000000);
  cursor: default;
}

button:not(:disabled):hover {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
}

button:not(:disabled):active {
  background: linear-gradient(90deg, #5d0076, #000000);
}

.header-grid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-grid button {
  width: max-content;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  border: 1px solid #000;
  min-width: 15rem;
}

.page3-grid {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
}

.page3-grid-card {
  width: 100%;
  padding: 2.5rem;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.page3-grid-card>a {
  width: 100%;
}

.page3-grid-card img {
  width: 10rem;
}

.page3-grid-card h3 {
  margin: 2.5rem 0 0.5rem 0;
}

.page2-hero-grid p {
  margin: 5rem 0;
  font-size: 2rem;
}

.page2-hero-grid input {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.65rem;
  color: #fff;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
  transition: 0.35s;
}

.page2-hero-grid input::placeholder {
  color: #fff;
  opacity: 0.65;
  font-size: 1.65rem;
}

.page2-hero-grid input:focus,
.page2-hero-grid input:hover {
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.page2-cards-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
  padding: 10rem 0;
}

.page2-card {
  width: 100%;
  padding: 2.5rem;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.3);
}

footer {
  width: 100%;
  background: rgba(255, 255, 255, 0.075);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 3.5rem 0;
}

.footer-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-template-rows: auto;
  grid-gap: 5rem;
}

.footer-grid-left img {
  height: 6.5rem;
  object-fit: contain;
}

.footer-grid-left {
  width: 100%;
  display: flex;
  align-items: center;
}

.footer-grid-right {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
}

.footer-grid-right a {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.65rem;
  color: #fff;
}

.footer-grid-right a>svg {
  font-size: 2rem;
  margin: 0 1rem 0 0;
}

.footer-grid-right a>span svg {
  font-size: 1.65rem;
  margin: 0 0 0 1rem;
  opacity: 0;
  transition: 0.35s;
}

.footer-grid-right a:hover>span svg {
  opacity: 1;
  transform: translate(30%, -30%);
}

.footer-grid-right a:hover {
  text-decoration: underline;
}

.page2-hero-grid-left button {
  display: none;
}

.links-page {
  width: 100%;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.links-page a {
  font-size: 2rem;
  color: #fff;
  margin: 0 0 3.5rem 0;
}

.app-submission-success {
  width: 100%;
  max-width: 500px;
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(255, 255, 255, 0.25);
  padding: 1.5rem 2.5rem;
  font-size: 1.65rem;
  color: #fff;
  text-align: center;
}

.info-box {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
}

.info-box-content {
  width: 100%;
  max-width: 1000px;
  height: 100%;
  max-height: 90vh;
  background-color: #fff;
  padding: 2.5rem;
  overflow-y: auto;
}

.info-box-content-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-box-content-head h3 {
  color: #000;
}

.info-box-content-head svg {
  font-size: 3rem;
  cursor: pointer;
}

.appid-box {
  width: 100%;
  margin: 3.5rem 0 0 0;
  padding: 2.5rem;
  border-radius: 1.5rem;
  background-color: #f7f8f9;
}

.appid-box img {
  width: 100%;
}

.appid-box p {
  color: #000;
  margin: 1rem 0 2.5rem 0;
}

.appid-box p b {
  font-weight: 600;
}

.appid-box h4 {
  color: #000;
  font-weight: 500;
  margin: 0;
}

.page2-hero-grid-input-box {
  width: 100%;
  position: relative;
}

.page2-hero-grid-input-box h6 {
  font-size: 1.65rem;
  font-weight: 400;
  color: #fff;
  margin: 1.5rem 0 0 0;
}

.page2-hero-grid-input-box h6 a {
  text-decoration: underline;
  color: #fff;
}

.page2-container .recharts-wrapper {
  width: 100% !important;
}

.suggestions-box {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, calc(100% + 1.5rem));
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 1000;
  width: 100%;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.suggestions-box h5 {
  color: #000000;
  font-size: 1.65rem;
  cursor: pointer;
  font-weight: 500;
  margin: 0;
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  text-align: left;
}

.suggestions-box h5:hover {
  color: #5d0076;
  background-color: rgba(92, 0, 118, 0.15);
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #757575;
  font-weight: 400;
  gap: 2.5rem;
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

.multi-ripple {
  width: 4rem;
  height: 4rem;
  position: relative;
}

.multi-ripple div:nth-child(1) {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.3rem solid black;
  animation: 1.5s ripple infinite;
}

.multi-ripple div:nth-child(2) {
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 0.3rem solid black;
  animation: 1.5s ripple infinite;
  animation-delay: 0.5s;
}

.page1-container .glass-box h2 {
  text-transform: capitalize;
  margin: 0 0 2.5rem 0;
}

@media screen and (max-width: 1250px) {
  .page2-hero-grid {
    grid-template-columns: 1fr;
  }

  .page2-hero-grid-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .page2-hero-grid-input-box svg {
    font-size: 2rem;
  }

  .page2-hero-grid-input-box span {
    margin: 0 0 0 1rem;
  }

  .info-box-content {
    max-height: 100vh;
    padding: 2rem;
  }

  .appid-box {
    margin: 2.5rem 0 0 0;
    padding: 1rem;
    border-radius: 0.5rem;
  }

  .info-box-content-head svg,
  .info-box-content-head h3,
  .page4-form h3 {
    font-size: 2rem;
  }

  .page2-cards-grid {
    grid-template-columns: 1fr;
  }

  .footer-grid {
    grid-template-columns: 1fr;
  }

  .page3-grid {
    grid-template-columns: 1fr;
  }

  .page4-form,
  .page2-card,
  .page3-grid-card {
    padding: 2rem;
  }
}

.sd-pc {
  display: block;
}

.sd-mobile {
  display: none;
}

@media screen and (max-width: 900px) {
  .sd-pc {
    display: none;
  }

  .sd-mobile {
    display: block;
  }

  .sl-grid {
    grid-template-columns: 1fr;
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 750px) {
  .footer-grid-right {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
  }

  .loading-screen .glass-box>p {
    font-size: 1.5rem;
  }

  .ppmsdb2 {
    padding: 5rem 0;
  }

  .footer-grid-left img {
    height: 5rem;
  }

  text,
  tspan,
  .recharts-legend-item-text {
    font-size: 1.25rem;
  }

  .page1-container .glass-box h2 {
    font-size: 3rem;
    margin: 0 0 1.5rem 0;
  }

  h3 {
    font-size: 2.25rem;
  }

  h1 {
    font-size: 3rem;
  }

  .page2-hero-grid {
    padding: 5rem 0;
  }

  .page3-hero-grid>p {
    font-size: 1.65rem;
  }

  .page2-hero-grid p {
    font-size: 1.65rem;
    margin: 2.5rem 0;
  }

  .page2-hero-grid-left a {
    width: 100%;
  }

  .page2-cards-grid {
    padding: 5rem 0 10rem 0;
  }

  .index-page .header-grid button {
    display: none;
  }

  .page2-hero-grid-left button {
    display: block;
    margin: 2.5rem 0 0 0;
    width: 100%;
  }

  .logo-box img {
    height: 5rem;
  }

  .sentiment-level-value-box {
    padding: 2rem;
  }

  .box {
    padding: 0 2rem;
  }

  th {
    font-size: 1.65rem;
  }

  .sentiment-timeline-chart-box,
  .sd-grid,
  .sl-grid,
  .glass-box {
    padding: 0;
    border: 0;
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
    background: transparent;
    box-shadow: none;
  }

  .sl-grid {
    grid-template-columns: 1fr;
  }

  .sentiment-timeline-chart-box {
    height: 25rem;
  }

  .clinical-domain-analysis-box {
    height: 25rem;
  }
}

@media screen and (max-width: 650px) {
  button {
    font-size: 1.5rem;
  }

  .precede-chart-box .recharts-polar-angle-axis-ticks .recharts-polar-angle-axis-tick:nth-child(2) {
    transform: translate(-10%, 15px);
  }

  .precede-chart-box .recharts-polar-angle-axis-ticks .recharts-polar-angle-axis-tick:nth-child(3) {
    transform: translate(10%, 15px);
  }

  .page2-hero-grid input {
    font-size: 1.5rem;
  }

  .page2-hero-grid input::placeholder {
    font-size: 1.5rem;
  }

  .glass-box-locked-box p {
    font-size: 1.85rem;
    font-weight: 600;
  }
}

@media screen and (max-width: 550px) {
  .precede-chart-box .recharts-polar-angle-axis-ticks .recharts-polar-angle-axis-tick:nth-child(2) {
    transform: translate(-20%, 15px);
  }

  .precede-chart-box .recharts-polar-angle-axis-ticks .recharts-polar-angle-axis-tick:nth-child(3) {
    transform: translate(20%, 15px);
  }
}

@media screen and (max-width: 450px) {
  .precede-chart-box .recharts-polar-angle-axis-ticks .recharts-polar-angle-axis-tick:nth-child(2) {
    transform: translate(-30%, 15px);
  }

  .precede-chart-box .recharts-polar-angle-axis-ticks .recharts-polar-angle-axis-tick:nth-child(3) {
    transform: translate(30%, 15px);
  }

  .sentiment-chart-box {}

  .sentiment-chart-box .recharts-wrapper {}
}